<template>
  <div class="fp-wrapper">
    <div class="modal-window">
      <div class="content content_form">
        <form-header />
        <h2 class="h2 content__h2">Обновите пароль</h2>

        <section class="content__section">
          <p class="content__section-text">Придумайте и введите новый пароль для вашего аккаунта</p>
        </section>
        <form-observer :validation-schema="resetPasswordSchema" @submit="onSubmit">
          <input-password
            name="password"
            label-text="Новый пароль"
            :is-show-password="true"
            placeholder="Введите пароль"
          />
          <input-password
            name="password_repeat"
            label-text="Повторите пароль"
            :is-show-password="true"
            placeholder="Повторите пароль"
          />
          <div class="content__section">
            <button class="button">Обновить пароль</button>
          </div>
        </form-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { Form as FormObserver } from 'vee-validate'
import InputPassword from '@/components/common/InputPassword'
import { resetPasswordSchema } from '@/validation'
import FormHeader from '@/components/common/FormHeader'
import parseUrl from '@/assets/helpers/parse_url'

export default {
  name: 'ResetPassword',
  components: {
    FormObserver,
    InputPassword,
    FormHeader
  },
  data () {
    return {
      resetPasswordSchema
    }
  },
  mounted () {
    const queryParams = parseUrl(window.location).searchObject
    if (!queryParams || !queryParams.token) {
      this.$router.push({
        name: '404',
        params: {
          title: 'Ошибка обновления пароля',
          text: 'Уникальный токен отсутствует. Пожалуйста, попробуйте снова или обратитесь к нам в тех. поддержку.'
        }
      })
    }
  },
  methods: {
    onSubmit (values) {
      const requestBody = {
        password_reset_token: parseUrl(window.location).searchObject.token,
        password: values.password
      }
      this.$axios.post(this.getPathRequest('reset_password_confirm'), requestBody)
        .then(response => {
          if (response.data.status) {
            this.$router.push({ name: 'Main' })
          }
        })
        .catch(error => {
          this.openModal('ErrorModal', {
            title: 'Ошибка изменения пароля',
            text: error.response.data.message
              ? error.response.data.message
              : 'Во время изменения пароля произошла ошибка. Пожалуйста, попробуйте снова или обратитесь к нам в тех. поддержку.'
          })
        })
    }
  }
}
</script>
